import React, { useState } from 'react';
import './App.css';
import WelcomeScreen from './WelcomeScreen';
import ParallaxImages from './ParallaxImages';
import Buttons from './Buttons';
import Image360 from './Image360';
import RotateIcono  from './RotateIcono';
import GooogleMaps from './GooogleMaps';
import GoogleMapsBotton from './GoogleMapsBotton';
import Submenu from './imagenn360';
import ModeloTree from './ModeloTree';
import Modelos3d from './Modelos3d';
import ButtonGoogle from './ButtonGoogle';
import Columnas from './Columnas';
import Three360 from './Three360';
import GoogleMapsPrueba from './GoogleMapsPrueba';
import Image3601 from './image3601';
import Whatsapp from './WhatsApp';




function App() {
  const [showWelcome, setShowWelcome] = useState(true);

  const handleWelcomeClose = () => {
    setShowWelcome(false);
  };

  return (
    <div>
      <RotateIcono/>
      {/* {showWelcome && <WelcomeScreen onClose={handleWelcomeClose} />}
      {!showWelcome && <RotateIcono />} */}
    </div>
  );
}

export default App;
