import React, { useState, useRef, useEffect } from 'react';
import './RotateIcono.css';
import Menu from './Buttons';
import CarouselUtils from './CarouselUtils';
import GoogleMapsBotton from './GoogleMapsBotton';


const RotateIcono = () => {
  const [currentImage, setCurrentImage] = useState(1);
  const isMouseDown = useRef(false);
  const isTouching = useRef(false);
  const startX = useRef(null);
    
  const handleImageChange = (direction) => {
    if (direction === 'next') {
      if (currentImage < totalImages) {
        setCurrentImage(currentImage + 1);
      } else {
        // Si estamos en la última imagen, volvemos a la primera
        setCurrentImage(1);
      }
    } else if (direction === 'previous') {
      if (currentImage > 1) {
        setCurrentImage(currentImage - 1);
      } else {
        // Si estamos en la primera imagen, avanzamos a la última
        setCurrentImage(totalImages);
      }
    }
  };
  
  
  const handleMouseDown = (e) => {
    isMouseDown.current = true;
    
    startX.current = e.clientX;
  };

  const handleMouseUp = () => {
    isMouseDown.current = false;
    startX.current = null;
  };

  const handleTouchStart = (e) => {
    isTouching.current = true;
    startX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    isTouching.current = false;
    startX.current = null;
  };

  const handleTouchMove = (e) => {
    if (isTouching.current) {
      const deltaX = e.touches[0].clientX - startX.current;
      if (deltaX > 30) {
        handleImageChange('next'); // Cambia a la imagen siguiente
        startX.current = e.touches[0].clientX;
      } else if (deltaX < -30) {
        handleImageChange('previous'); // Cambia a la imagen anterior
        startX.current = e.touches[0].clientX;
      }
    }
  };

  const handleMouseMove = (e) => {
    if (isMouseDown.current) {
      const deltaX = e.clientX - startX.current;
      if (deltaX > 30) {
        handleImageChange('next'); // Cambia a la imagen siguiente
        startX.current = e.clientX;
      } else if (deltaX < -30) {
        handleImageChange('previous'); // Cambia a la imagen anterior
        startX.current = e.clientX;
      }
    }
  };

  useEffect(() => {
    // Limpia los valores cuando el componente se desmonta
    return () => {
      isMouseDown.current = false;
      isTouching.current = false;
      startX.current = null;
    };
  }, []);

//   codigo/// llevar a una imagen seleccionada
const totalImages = 66; // Número total de imágenes en el carrusel
const animationSpeed = 40; // Velocidad de la animación en milisegundos (ajusta según tu preferencia)
const animationInterval = useRef(null);

// Función para cambiar la imagen actual al número deseado
const goToImage = (imageNumber) => {
  if (imageNumber >= 1 && imageNumber <= totalImages) {
    clearInterval(animationInterval.current); // Detiene la animación actual
    animateToImage(imageNumber);
  }
};

// Función para realizar la animación de rotación suave
const animateToImage = (targetImage) => {
  const initialImage = currentImage;
  const direction = targetImage > initialImage ? 1 : -1;

  // Realiza una animación suave hacia la imagen deseada
  animationInterval.current = setInterval(() => {
    setCurrentImage((prevImage) => {
      const nextImage = prevImage + direction;
      if (nextImage === targetImage) {
        clearInterval(animationInterval.current); // Detiene la animación al llegar a la imagen deseada
      }
      return nextImage;
    });
  }, animationSpeed);
};
//////////////// para googlemaps

////////////////
  return (
    <div
      className="parallax-container"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
    <div className="parallax-container">
    
        <div className="parallax-images">
        <img
          src={`/image${currentImage}.jpg`}
          alt={`Image ${currentImage}`}
          onDragStart={(e) => e.preventDefault()}
        />
      </div>  
     {/* <button className='rotate-button' onClick={() => goToImage(1)}>  Cambia a la imagen deseada (por ejemplo, imagen 20) 
         Ir a la Imagen 20
       </button>*/}
       
       <Menu  goToImage={goToImage} totalImages={totalImages} /> 
       
     </div>
    </div>
  );
};

export default RotateIcono;     