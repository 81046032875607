import React, { Component } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';

class GoogleMapsPrueba extends Component {
  constructor(props) {
    super(props);
    this.state = {
      circles: [],
      polyline: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coordenadas !== this.props.coordenadas || prevProps.zoom !== this.props.zoom) {
      this.limpiarElementosAnteriores();
      this.dibujarElementos();
    }
  }

  limpiarElementosAnteriores = () => {
    this.state.circles.forEach((circle) => circle.setMap(null));

    if (this.state.polyline) {
      this.state.polyline.setMap(null);
    }

    this.setState({ circles: [], polyline: null });
  };

  dibujarElementos = () => {
    const { coordenadas, zoom } = this.props;

    if (Array.isArray(coordenadas) && coordenadas.length > 0) {
      const circles = coordenadas.map((coordenada, index) => (
        new this.props.google.maps.Circle({
          center: coordenada,
          radius: 20,
          strokeColor: 'red',
          strokeOpacity: 1,
          strokeWeight: 5,
          fillColor: 'rgba(255, 255, 255, 0.8)',
          map: this.map,
        })
      ));

      if (coordenadas.length > 1) {
        const polyline = new this.props.google.maps.Polyline({
          path: coordenadas,
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 10,
          map: this.map,
        });

        this.setState({ polyline });
      }

      this.setState({ circles });

      this.teletransportarMapa(coordenadas[0].lat, coordenadas[0].lng, zoom);
    }
  };

  teletransportarMapa = (lat, lng, zoom) => {
    if (this.map && this.map.map) {
      const { google } = this.props;
      const map = this.map.map;

      // Animación para mover la cámara a la nueva posición
      map.panTo(new google.maps.LatLng(lat, lng));
      map.setZoom(zoom);
    }
  };

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={this.props.zoom}
        initialCenter={{ lat: 2.485108, lng: -76.564807 }}
        onReady={(mapProps, map) => {
          this.map = map;
          this.dibujarElementos();
        }}
      />
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCFZNVDf7Wsipe3sFfbKoKOnYvpg99rOHc'
})(GoogleMapsPrueba);