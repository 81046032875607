import React, { Component } from 'react';
import 'aframe';
import './image360.css';

class Image360 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 'Panorama16.jpg', // Cambiado a 'Panorama17.png'
    };
    this.rendererContainer = React.createRef(); // Utiliza una referencia para acceder al contenedor
  }

  changeImage = (newImage) => {
    // Cambia la imagen cuando se hace clic en el botón
    this.setState({
      currentImage: newImage,
    });
  };

  componentWillUnmount() {
    // Limpiar la escena y controles al desmontar el componente
    const rendererContainer = this.rendererContainer.current;
    if (rendererContainer && rendererContainer.firstChild) {
      rendererContainer.removeChild(rendererContainer.firstChild);
    }
  }

  render() {
    return (
      <div ref={this.rendererContainer}>
        <a-scene className="scene-container" vr-mode-ui="enabled: true"></a-scene>
        <a-scene className="scene-container">
          <a-sky src={this.state.currentImage}></a-sky>
          <a-camera
            look-controls="enabled: true; touchEnabled: true; hmdEnabled: false; magicWindowTracking: true;"
            cursor="rayOrigin: mouse;"
            rotation="0 -90 90"
            
          ></a-camera>
          <a-text
            font="kelsonsans"
            value="InnovArq"
            width="6"
            position="-2.5 0.25 -1.5"
            rotation="0 15 0"
            color= "#000000"
          ></a-text>
           <a-text
            font="kelsonsans"
            value="....."
            width="6"
            position="-1.5 0.45 -1.5"
            rotation="0 15 0"
            color= "#1E90FF"
          ></a-text>

          {/* Botones circulares para cambiar la imagen */}
           {this.state.currentImage === 'Panorama16.jpg' && (
            <a-circle
              src="icono4.png"
              radius="0.5"
              position="-3.5 -3 0"
              onClick={() => this.changeImage('Panorama17.jpg')}
              rotation="80 45 90"
              material="side: double; transparent: true; opacity: 1"
            ></a-circle>
          )} 

          {this.state.currentImage === 'Panorama17.jpg' && (
            <>
              <a-circle
                src="icono4.png"
                radius="0.5"
                position="2.5 -3 1"
                onClick={() => this.changeImage('Panorama16.jpg')}
                rotation="80 60 90"
                material="side: double; transparent: true; opacity: 1"
              ></a-circle>
              <a-circle
                src="icono4.png"
                radius="0.5"
                position="-3.5 -1.5 -0.5"
                onClick={() => this.changeImage('Panorama18.jpg')}
                rotation="80 45 90"
                material="side: double; transparent: true; opacity: 1"
              ></a-circle>
            </>
          )}

          {this.state.currentImage === 'Panorama18.jpg' && (
            <>
             <a-circle
                src="icono4.png"
                radius="0.5"
                position="1.5 -1.5 -1.9"
                onClick={() => this.changeImage('Panorama17.jpg')}
                rotation="80 60 90"
                material="side: double; transparent: true; opacity: 1"
              ></a-circle>
             <a-circle
                src="icono4.png"
                radius="0.5"
                position="-1.7 -2 -1"
                onClick={() => this.changeImage('Panorama19.jpg')}
                rotation="80 45 90"
                material="side: double; transparent: true; opacity: 1"
              ></a-circle>
               <a-circle
                src="icono4.png"
                radius="0.5"
                position="-2 -3 2"
                onClick={() => this.changeImage('Panorama20.jpg')}
                rotation="80 60 90"
                material="side: double; transparent: true; opacity: 1"
              ></a-circle>
            </>
          )}
          {this.state.currentImage === 'Panorama19.jpg' && (
            <>
             <a-circle
                src="icono4.png"
                radius="0.5"
                position="1 -2 0.6"
                onClick={() => this.changeImage('Panorama18.jpg')}
                rotation="80 60 90"
                material="side: double; transparent: true; opacity: 1"
              ></a-circle>
            
            </>
          )}
           {this.state.currentImage === 'Panorama20.jpg' && (
            <>
              <a-circle
                src="icono4.png"
                radius="0.5"
                position="-1.7 -2.5 -0.5"
                onClick={() => this.changeImage('Panorama21.jpg')}
                rotation="80 60 90"
                material="side: double; transparent: true; opacity: 1"
              ></a-circle>
              <a-circle
                src="icono4.png"
                radius="0.5"
                position="1.5 -2 0.4"
                onClick={() => this.changeImage('Panorama18.jpg')}
                rotation="80 45 90"
                material="side: double; transparent: true; opacity: 1"
              ></a-circle>
            </>
          )}
          
          {this.state.currentImage === 'Panorama21.jpg' && (
            <>
              <a-circle
                src="icono4.png"
                radius="0.5"
                position="-1.7 -2 0.1"
                onClick={() => this.changeImage('Panorama22.jpg')}
                rotation="80 60 90"
                material="side: double; transparent: true; opacity: 1"
              ></a-circle>
              <a-circle
                src="icono4.png"
                radius="0.5"
                position="2 -1.5 0.2"
                onClick={() => this.changeImage('Panorama20.jpg')}
                rotation="80 45 90"
                material="side: double; transparent: true; opacity: 1"
              ></a-circle>
            </>
          )}
          {this.state.currentImage === 'Panorama22.jpg' && (
            <>
              <a-circle
                src="icono4.png"
                radius="0.5"
                position="2 -2 0.5"
                onClick={() => this.changeImage('Panorama21.jpg')}
                rotation="80 60 90"
                material="side: double; transparent: true; opacity: 1"
              ></a-circle>
            
            </>
          )}
        
        </a-scene>
      </div>
    );
  }
}

export default Image360;