import React from 'react';
import './Buttons.css';

const Recorridos = ({ toggleBotonLobbyAdicional1, toggleBotonLobbyAdicional2,toggleBotonLobbyAdicional3, mostrarBotonLobbyAdicional1, mostrarBotonLobbyAdicional2,mostrarBotonLobbyAdicional3, mostrarVideoNormal, mostrarVideoFunc }) => {
  return (
    <div className="submenu2">
      <button className='boton9' onClick={() => {toggleBotonLobbyAdicional1();}}>
        <img className="boton8" src="/imagen.png" alt="Image"></img>Imagen1
      </button>
      {mostrarBotonLobbyAdicional1 && (
        <img className="boton-circular1" src="/image50.jpg" alt="Image" />
      )}
      <button className='boton9' onClick={() => {toggleBotonLobbyAdicional2();}}>
        <img className="boton8" src="/imagen.png" alt="Image"></img>Imagen2
      </button>
      {mostrarBotonLobbyAdicional2 && (
        <img className="boton-circular1" src="/image60.jpg" alt="Image"/>
      )}
      <button className='boton9' onClick={() => {toggleBotonLobbyAdicional3();}}>
        <img className="boton8" src="/video.png" alt="Image"></img>Video.
      </button>
      {mostrarVideoNormal && (
        // Aquí puedes mostrar el video
        <div>
          <iframe className="boton-circular2"
            src="https://www.youtube.com/embed/49-zy-63biM?autoplay=1" // Reemplaza la URL con la del video de YouTube, agregando "?autoplay=1" para que comience automáticamente
            title="YouTube Video"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Recorridos;