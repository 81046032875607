import React, { Component } from 'react';
import { Map, Marker, Polyline, GoogleApiWrapper } from 'google-maps-react';
import GooogleMaps from './GooogleMaps';

class ButtonGoogle extends Component {
  constructor(props) {
    super(props);
    this.state = {
    buttons: [
      {
        name: ' Salud.',
        coordinates: [{ lat: 2.484800, lng: -76.565616 }],
        zoom: 16,
        buttonImage: './saludable.png',
        mapImages: [
          { image: './hospital.png', coords: { lat: 2.490057, lng: -76.560023 } },
          { image: './hospital.png', coords: { lat: 2.482085, lng: -76.563893} },
          // Agrega más objetos según sea necesario
        ],
        route: [
          { lat:  2.486351, lng: -76.566876 },
          { lat: 2.482085, lng: -76.563893 },
          { lat: 2.481164, lng: -76.567112},
        ],
      },
      {
        name: 'Comercio',
        coordinates: [{ lat: 2.482033, lng: -76.9 }],
        zoom: 17,
        buttonImage: './tienda11.png',
        mapImages: [
          { image: './tienda1.png', coords: { lat: 2.490057, lng: -76.560023 } },
          { image: './tienda1.png', coords: { lat: 2.482085, lng: -76.563893} },
        ],
      },
      {
        name: 'Educacion',
        coordinates: [{ lat: 2.480000, lng: -76.570000 }],
        zoom: 18,
        buttonImage: './educacion11.png',
        mapImages: [
          { image: './colegio.png', coords: { lat: 2.490057, lng: -76.560023  } },
          { image: './colegio.png', coords: { lat: 2.482085, lng: -76.563893} },
        ],
      },
    ],
    activeButton: null,
    mostrarMapa: false,
    coordenadasMapa: [],
    zoom: 15,
    routeCoordinates: [],
    };
  }

  handleButtonClick = (button) => {
    const buttonData = this.state.buttons.find(b => b.name === button);

    this.setState({
      activeButton: buttonData,
      mostrarMapa: true,
      coordenadasMapa: buttonData.coordinates,
      zoom: buttonData.zoom,
      routeCoordinates: buttonData.route || [],
    });
  };

  render() {
    return (
      <div>
        {/* Botones del menú */}
        {this.state.buttons.map((button, index) => (
          <button key={index} className="BotonMenu1" onClick={() => this.handleButtonClick(button.name)}>
            <img className="Logo4" src={button.buttonImage} alt="Image" />
            {button.name.charAt(0).toUpperCase() + button.name.slice(1)}
          </button>
        ))}

        {/* Mostrar el mapa */}
        {this.state.mostrarMapa && (
          
          <div className="mapa-container">
          
            <Map
            
              google={this.props.google}
              zoom={this.state.zoom}
              style={{ width: '100%', height: '100%' }}
              initialCenter={this.state.coordenadasMapa[0]}
              onReady={(mapProps, map) => {
                map.panTo(this.state.coordenadasMapa[0]);
                map.setZoom(this.state.zoom);
              }}
             >
              
              {/* Coloca marcadores en las coordenadas del botón activo */}
              {this.state.activeButton.mapImages.map((imageData, index) => (
                <Marker
                  key={index}
                  position={imageData.coords}
                  icon={{
                    url: imageData.image,
                    scaledSize: new this.props.google.maps.Size(40, 40),
                  }}
                />
              ))}

              {/* Dibuja la polilínea de la ruta si hay coordenadas */}
              {this.state.routeCoordinates.length > 1 && (
                <Polyline
                  path={this.state.routeCoordinates}
                  strokeColor="#FF0000"
                  strokeOpacity={1.0}
                  strokeWeight={5}
                />
              )}
               {/* Agrega la imagen edificio.png como marcador fijo */}
               <Marker
                position={{ lat: 2.485323, lng: -76.563895 }}
                icon={{
                  url: './circular.png',
                  scaledSize: new this.props.google.maps.Size(100, 100),
                }}
              />
              
            </Map>
          </div>
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCFZNVDf7Wsipe3sFfbKoKOnYvpg99rOHc',
})(ButtonGoogle);