import React, { useState, useRef, useEffect, Component } from 'react';
import 'aframe';
import Image360 from './Image360';
import Image3601 from './image3601';
import CarouselUtils from './CarouselUtils';
import GooogleMaps from './GooogleMaps';
import ButtonGoogle from './ButtonGoogle';
import './Buttons.css';
import { DiscreteInterpolant } from 'three';
import Recorridos from './Recorridos';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mostrarBotonLobbyAdicional1: false,
      mostrarBotonLobbyAdicional2: false,
      activeButton: null,
      mostrarImagen360: false,
      mostrarMapa: false,
      coordenadasMapa: [],
      zoom: 15,
      enImagen360: false,
      mostrarVideoNormal: false,
    };
  }

  handleButtonClick = (button, destino, zoom) => {
    this.setState((prevState) => ({
      activeButton: prevState.activeButton === button ? null : button,
      mostrarImagen360: false,
      mostrarMapa: button === "button3",
      mostrarBotonLobbyAdicional: false,
      mostrarBotonLobbyAdicional1: false,
      mostrarBotonLobbyAdicional2: false,
      mostrarMapaSalud: false,                   
      coordenadasMapa: destino,
      zoom: zoom || 15,
      mostrarMapa: true,
      mostrarVideoNormal: button === "button4",
     
    }));
  };
  toggleBotonLobbyAdicional1 = () => {
    this.setState((prevState) => ({
      mostrarBotonLobbyAdicional1: !prevState.mostrarBotonLobbyAdicional1,
    }));
  };

  toggleBotonLobbyAdicional2 = () => {
    this.setState((prevState) => ({
      mostrarBotonLobbyAdicional2: !prevState.mostrarBotonLobbyAdicional2,
    }));
  };
  toggleBotonLobbyAdicional = () => {
    this.setState((prevState) => ({
      mostrarBotonLobbyAdicional: !prevState.mostrarBotonLobbyAdicional,
    }));
  };
  
  toggleBotonLobbyAdicional1 = () => {
    this.setState((prevState) => ({
      mostrarBotonLobbyAdicional1: !prevState.mostrarBotonLobbyAdicional1,
    }));
  };
  
  toggleBotonLobbyAdicional2 = () => {
    this.setState((prevState) => ({
      mostrarBotonLobbyAdicional2: !prevState.mostrarBotonLobbyAdicional2,
    }));
  };
   
  mostrarImagen360Func = () => {
    this.setState({ mostrarImagen360: true, enImagen360: true });
  };
  mostrarImagen3601Func = () => {
    this.setState({ mostrarImagen3601: true, enImagen3601: true });
  };

  salirDeImagen360 = () => {
    this.setState({ mostrarImagen360: false, enImagen360: false });
  };
  salirDeImagen3601 = () => {
    this.setState({ mostrarImagen3601: false, enImagen3601: false });
  };

  render() {
    const { goToImage, totalImages } = this.props;

    return (
      <div className="menu1">
        <div className='menu'>
          
            <div>
               <img className="logo" src="/logo11.jpeg" alt="Image"></img>
            </div>

            <button className="BotonMenu" onClick={() => this.handleButtonClick("button1")}>
               <img className="Logo1" src="/edificio11.png" alt="Image"></img><text className='text3'>Etapas</text>
            </button>

            <button className="BotonMenu" onClick={() => this.handleButtonClick("button2")}>
              <img className="Logo2" src="/trabajar.png" alt="Image"></img><text className='text3'>Amenidades</text>
            </button>

            <button className="BotonMenu" onClick={() => this.handleButtonClick("button3")}>
               <img className="Logo3" src="/ubicacion.png" alt="Image"></img><text className='text3'>Beneficios</text>
            </button>

            <button className="BotonMenu" onClick={() => this.handleButtonClick("button4")}>
               <img className="Logo4" src="/Camara.png" alt="Image"></img><text className='text3'>Recorridos</text>
            </button>

       </div>
         
      
      
      {this.state.activeButton === "button1" && (
          <div className='submenu1' >
             
                <text className='bienvenido'>Bienvenido</text>
                    
                      <button className='boton6' onClick={() => {this.toggleBotonLobbyAdicional();
                        this.setState({ mostrarBotonLobbyAdicional1: false });
                        this.setState({ mostrarBotonLobbyAdicional2: false })}}>
                         <text className="text1">Torre 1</text>
                      </button>
                        {this.state.mostrarBotonLobbyAdicional && (
                         <button className="Logo6"  onClick={this.mostrarImagen360Func}>Tipo 1</button>
                        )}
                       {this.state.mostrarBotonLobbyAdicional && (
                        <button className="Logo6"  onClick={this.mostrarImagen3601Func}>Tipo 2</button>
                        )}
                       {this.state.mostrarBotonLobbyAdicional && (
                        <button className="Logo6"  onClick={this.mostrarImagen360Func}>Tipo 3</button>
                        )}
                       {this.state.mostrarBotonLobbyAdicional && (
                        <button className="Logo6"  onClick={this.mostrarImagen360Func}>Tipo 4</button>
                        )}
                      <button className='boton6' onClick={this.mostrarImagen360Func}>
                           <text className="text1">Torre 2</text>
                      </button>
                      <button className='boton6' onClick={this.mostrarImagen360Func}>
                           <text className="text1">Torre 3</text>
                      </button>
           
                     
            </div>
        )}
        

        
      
        {this.state.activeButton === "button2" && (
            <div className='submenu2'>
            
              <button className='boton9' onClick={() => {
                goToImage(1);
                this.toggleBotonLobbyAdicional();
                this.setState({ mostrarBotonLobbyAdicional1: false });
                this.setState({ mostrarBotonLobbyAdicional2: false })}}>
                <img className="boton8" src="/recepcion.png" alt="Image"></img>Lobby
              </button>
              {this.state.mostrarBotonLobbyAdicional &&  (
                
                <img className="boton-circular" src="/lobby1.png" alt="Image" onClick={this.mostrarImagen360Func}/>
                
              )}
              <button className='boton9' onClick={() => { goToImage(60);this.toggleBotonLobbyAdicional1();
                this.setState({ mostrarBotonLobbyAdicional: false });
                this.setState({ mostrarBotonLobbyAdicional2: false })}}>
                <img className="boton8" src="/gym.png" alt="Image"></img>Gimnasio
              </button>
              {this.state.mostrarBotonLobbyAdicional1 && (
                <img className="boton-circular" src="/gym.png" alt="Image"onClick={this.mostrarImagen360Func}/>
              )}
              <button className='boton9' onClick={() => {goToImage(30) ;
                this.toggleBotonLobbyAdicional2();this.setState({ mostrarBotonLobbyAdicional1: false });
                this.setState({ mostrarBotonLobbyAdicional: false })}}>
                <img className="boton8" src="/parque22.png" alt="Image"></img>Parque 
              </button>
              {this.state.mostrarBotonLobbyAdicional2 && (
                <img className="boton-circular" src="/Parque2.png" alt="Image"/>
              )}
            
          </div>
        )}

       

        {this.state.activeButton === "button3" && (
          <div className='GoogleBoton'>          
              <ButtonGoogle
              />           
          </div>
        )}
      
      {this.state.mostrarVideoNormal && (
          <Recorridos 
          toggleBotonLobbyAdicional1={this.toggleBotonLobbyAdicional1}
          toggleBotonLobbyAdicional2={this.toggleBotonLobbyAdicional2}
          toggleBotonLobbyAdicional3={this.toggleBotonLobbyAdicional3}
          mostrarBotonLobbyAdicional1={this.state.mostrarBotonLobbyAdicional1}
          mostrarBotonLobbyAdicional2={this.state.mostrarBotonLobbyAdicional2}
          mostrarVideoNormal={this.mostrarVideoNormal}
          />
          
          
               )}

        {this.state.mostrarImagen360 ? (
          <div className="imagen360-container">
            <button className='boton360 ' onClick={this.salirDeImagen360}><text className='text2'>X</text></button>
            <Image360
              /> 
          </div>
        ) : null}
         {this.state.mostrarImagen3601 ? (
          <div className="imagen360-container">
            <button className='boton360 ' onClick={this.salirDeImagen3601}><text className='text2'>X</text></button>
            <Image3601
              /> 
          </div>
        ) : null}
      </div>
    );
  }
}

export default Menu;